
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
@Component({})
export default class ImportSummarySidebar extends Vue {
  @Prop({ required: true, type: Object }) importResult!: any

  sidebar = sidebar

  get successRecordCount(): number {
    return this.importResult.successRecordCount || 0
  }

  get errorRecordCount(): number {
    return this.importResult.errorRecordCount || 0
  }

  get totalRecords(): number {
    return this.successRecordCount + this.errorRecordCount
  }

  get errors(): any[] {
    return this.importResult.errors || []
  }

  get warnings(): any[] {
    return this.importResult.warnings || []
  }

  get errorsByRow(): Record<string, string[]> {
    const errorsByRow = {}

    for (const error of this.errors) {
      const row = error.rowNumber.toString()
      if (!errorsByRow[row]) {
        errorsByRow[row] = []
      }
      errorsByRow[row].push(error.message)
    }

    return errorsByRow
  }
}
