export const checkoutTypes = [
  {
    checkoutTypeId: 1,
    checkoutTypeName: 'E-Checkout',
    key: 'e-checkout',
    description: '',
  },
  {
    checkoutTypeId: 2,
    checkoutTypeName: 'PDF',
    key: 'pdf',
    description: '',
  },
]

export const companyTypes = [
  {
    companyTypeId: 1,
    companyTypeName: 'Free',
    key: 'free',
    description: '',
  },
  {
    companyTypeId: 2,
    companyTypeName: 'Paid',
    key: 'paid',
    description: '',
  },
  {
    companyTypeId: 3,
    companyTypeName: 'Broker',
    key: 'broker',
    description: '',
  },
  {
    companyTypeId: 4,
    companyTypeName: 'Admin',
    key: 'admin',
    description: '',
  },
  {
    companyTypeId: 5,
    companyTypeName: 'Lead',
    key: 'lead',
    description: '',
  },
]
