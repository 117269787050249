
import sidebar from '@/store/modules/sidebar'
import { Vue, Component } from 'vue-property-decorator'
import AdminCreateCompanyForm from '@/components/AdminCreateCompanyForm.vue'

@Component({
  components: {
    AdminCreateCompanyForm,
  },
})
export default class AdminCreateCompanySidebar extends Vue {
  newCompanyId = null
  submitting = false

  handleCompanyCreated(id: number): void {
    this.newCompanyId = id
    this.submitting = false
    this.$emit('admin:company-created')
    sidebar.pop()
  }
}
