
import sidebar from '@/store/modules/sidebar'
import { Component, Prop, Vue } from 'vue-property-decorator'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import EditCompanyForm from '@/components/EditCompanyForm.vue'
import client from '@/services/company'
import socialLinksClient from '@/services/socialLinks'
import smsNumberClient from '@/services/smsNumber'
import { Company, SocialLink } from '@/models/dto/Company'
import typeClient from '@/services/type'
import { Address } from '@/models/dto'
import { formatAddressName } from '@/utils/string'
import { EventBus } from '@/utils/eventBus'
import { getCenteringDataByAddress } from '@/utils/address'
import AdminBulkImportOverviewSidebar from './AdminBulkImportOverviewSidebar.vue'

@Component({
  components: {
    AutocompleteAddress,
    EditCompanyForm,
  },
})
export default class AdminEditCompanyForm extends Vue {
  @Prop({ type: Number, required: true }) companyId: number
  submitting = false

  company: Company = null
  socialLinks: SocialLink[] = []

  clear(): void {
    sidebar.pop()
  }

  updateCompany(partialCompany: Partial<Company>): void {
    this.company = Object.assign({}, this.company, partialCompany)
  }

  updateCompanyAddress(partialAddress: Partial<Address>): void {
    this.company.address = Object.assign(
      {},
      this.company.address,
      partialAddress
    )
  }

  handleBulkImport(): void {
    sidebar.push({
      component: AdminBulkImportOverviewSidebar,
      title: 'Bulk Import',
      props: { companyId: this.companyId },
    })
  }

  async createSmsPhoneNumber(): Promise<void> {
    if (
      this.company.enableSmsNumber &&
      this.company.twilioApiPhoneNumber == null
    ) {
      try {
        await smsNumberClient.create(this.companyId)
      } catch (e: any) {
        EventBus.$emit('snackbar:error', 'Failed to create sms phone number')
      }
    }
  }

  async submit(): Promise<void> {
    const form: any = this.$refs.form
    if (!form.validate()) {
      return
    }
    this.submitting = true
    try {
      const addressName = formatAddressName(
        this.company.address || new Address()
      )
      const centeringData = await getCenteringDataByAddress(
        this.company.address
      )
      const newCompany = {
        ...this.company,
        address: {
          ...this.company.address,
          ...centeringData,
          name: addressName,
          addressName,
          title: null,
        },
      }

      await Promise.all([
        client.update(this.companyId, newCompany),
        this.saveSocialLinks(),
        this.createSmsPhoneNumber(),
      ])
    } catch {
      EventBus.$emit('snackbar:error', 'Error saving company.')
      this.submitting = false
      return
    }
    this.$emit('admin:company-updated')
    sidebar.close()
  }

  async saveSocialLinks(): Promise<void> {
    const promises = []
    for (const link of this.socialLinks) {
      if (link.companySocialLinkId) {
        promises.push(
          socialLinksClient.modifySocialLink(link.companySocialLinkId, link)
        )
      } else {
        promises.push(socialLinksClient.addSocialLink(this.companyId, [link]))
      }
    }
    await Promise.all(promises)
  }

  async loadCompany(): Promise<void> {
    const res = await client.byId(this.companyId)
    this.company = res.data.company
    if (!this.company.address) {
      this.company = { ...this.company, address: new Address() }
    }
  }

  async loadSocialLinks(): Promise<void> {
    const res = await socialLinksClient.tableView(this.companyId, {
      pageSize: -1,
    })
    this.socialLinks = res.data.resultList
  }

  async mounted(): Promise<void> {
    if (this.companyId) {
      const promises = []
      promises.push(this.loadCompany(), this.loadSocialLinks())
      await Promise.all(promises)
    }
  }
}
