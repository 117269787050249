
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop } from 'vue-property-decorator'
import BulkImportSidebar from './BulkImportSidebar.vue'
import { ImportStep } from '@/models/BulkImport'

@Component({})
export default class AdminBulkImportOverviewSidebar extends Vue {
  @Prop({ type: Number, required: true }) companyId: number

  importSteps: ImportStep[] = [
    {
      title: 'Industries (via UI)',
    },
    {
      title: 'Companies',
      component: BulkImportSidebar,
      props: {
        templateUrl: `${process.env.BASE_URL}templates/company-import-template.csv`,
        importType: 'company',
        requiredColumns: ['Name'],
        additionalInstructions: `<p>Create your
        <span class="font-bold">Industries</span>
        ahead of importing your companies.</p>`,
      },
    },
    {
      title: 'Contacts',
      component: BulkImportSidebar,
      props: {
        templateUrl: `${process.env.BASE_URL}templates/contact-import-template.csv`,
        importType: 'contact',
        requiredColumns: ['First Name', 'Last Name', 'Email', 'Phone'],
      },
    },
    {
      title: 'Drivers',
      component: BulkImportSidebar,
      props: {
        templateUrl: `${process.env.BASE_URL}templates/driver-import-template.csv`,
        importType: 'driver',
        requiredColumns: ['First Name', 'Last Name', 'Email', 'Phone'],
      },
    },
    {
      title: 'Garages (via UI)',
    },
    {
      // The following columns are required for every vehicle: Vehicle Name, Vehicle Type (Charter Bus, Mini Bus, Sprinter, Party Bus, Sedan, SUV, Limousine, Trolley, School Bus, or Van), Vehicle Make, Vehicle Model, Vehicle Year, Capacity, License Plate, VIN #, Garage. The other columns in the template are optional but must not be deleted.
      title: 'Vehicles',
      component: BulkImportSidebar,
      props: {
        templateUrl: `${process.env.BASE_URL}templates/vehicle-import-template.csv`,
        importType: 'vehicle',
        requiredColumns: `<p><span class="font-bold">Vehicle Name, Vehicle Type&nbsp;</span>(Charter Bus, Mini Bus, Sprinter, Party Bus, Sedan, SUV, Limousine, Trolley, School Bus, or Van),<span class="font-bold"> Vehicle Make, Vehicle Model, Vehicle Year, Capacity, License Plate, VIN #, Garage</span></p>`,
      },
    },
    {
      title: 'Reservations',
      component: BulkImportSidebar,
      props: {
        templateUrl: `${process.env.BASE_URL}templates/reservation-import-template.csv`,
        importType: 'reservation',
        requiredColumns: [
          'firstPickupAddress',
          'firstDropoffAddress',
          'firstPickupDatetime',
          'lastDropoffDatetime',
          'customerEmail',
        ],
      },
    },
    {
      title: 'Quotes',
      component: BulkImportSidebar,
      props: {
        templateUrl: `${process.env.BASE_URL}templates/reservation-import-template.csv`,
        importType: 'quote',
        requiredColumns: [
          'firstPickupAddress',
          'firstDropoffAddress',
          'firstPickupDatetime',
          'lastDropoffDatetime',
          'customerEmail',
        ],
      },
    },
  ]

  handleTitleClick(step: ImportStep): void {
    if (!step.component) {
      return
    }

    sidebar.push({
      component: step.component,
      title: `Bulk Import ${step.title}`,
      props: {
        companyId: this.companyId,
        ...(step.props || []),
      },
    })
  }
}
