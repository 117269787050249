
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUFileUpload from '@/components/CUFileUpload.vue'
import sidebar from '@/store/modules/sidebar'
import bulkImport from '@/services/import'
import ImportSummarySidebar from './ImportSummarySidebar.vue'
import { toTitle } from '@/utils/string'
import { BulkImportType } from '@/models/BulkImport'

@Component({
  components: { CUFileUpload },
})
export default class BulkImportSidebar extends Vue {
  @Prop({ type: Number, required: true }) companyId!: number
  @Prop({ type: String, required: true }) requiredColumns!: string[] | string
  @Prop({ type: String, required: false, default: null })
  additionalInstructions!: string
  @Prop({ type: String, required: true }) templateUrl!: string
  @Prop({ type: String, required: true }) importType!: BulkImportType

  file: File | null = null
  submitting = false

  get pluralImportType(): string {
    switch (this.importType) {
      case 'company':
        return 'companies'
      default:
        return `${this.importType}s`
    }
  }

  get requiredColumnsJoin(): string {
    let label = ''
    if (Array.isArray(this.requiredColumns)) {
      label = `<span class="font-bold">${this.requiredColumns.join(
        ', '
      )}</span>`
    } else {
      label = this.requiredColumns
    }
    return label
  }

  get uploadButtonLabel(): string {
    return `Upload ${toTitle(this.pluralImportType)} File`
  }

  async submit(): Promise<void> {
    this.submitting = true
    const formData = new FormData()
    formData.append('file', this.file)
    formData.append('companyId', String(this.companyId))

    let res

    try {
      res = await bulkImport[this.importType](formData)
      sidebar.close()
    } catch (err: any) {
      if (err.response) {
        res = err.response
      }
    } finally {
      this.submitting = false
      sidebar.push({
        component: ImportSummarySidebar,
        title: 'Import Summary',
        props: {
          importResult: res.data.importResult,
        },
      })
    }
  }

  handleFileUpload(file: File): void {
    this.file = file
  }

  handleFileDelete(): void {
    this.file = null
  }

  clear(): void {
    sidebar.pop()
  }
}
