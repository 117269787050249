
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { validateRules, validationRules } from '@/utils/rules'
import companyClient from '@/services/company'
import smsNumberClient from '@/services/smsNumber'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { Address } from '@/models/dto'
import { states } from '@/utils/states'
import { formatAddressName } from '@/utils/string'
import { hasRequiredAddressFields } from '@/utils/validators'
import { Company } from '@/models/dto/Company'
import { companyTypes } from '@/data/company'
import { getCenteringDataByAddress } from '@/utils/address'
import { EventBus } from '@/utils/eventBus'
import { parseAxiosError } from '@/utils/error'

const DEFAULT_LINK =
  'https://www.busify.com/?utm_source=affiliate&utm_medium=email&utm_campaign=farmout'
const DEFAULT_SLOGAN = 'Convert 10x more quotes'

@Component({
  components: {
    AutocompleteAddress,
  },
})
export default class AdminCreateCompanyForm extends Vue {
  @Prop({ type: Boolean, required: false }) submitting: boolean

  rules = validationRules
  states = states

  company: Partial<Company> = {
    name: '',
    companyType: null,
    emailSenderName: '',
    email: '',
    phone: '',
    opsPhone: '',
    dotNumber: '',
    address: new Address(),
    showCharterUPIntegration: false,
    showBusifyPayIntegration: true,
    showSquareIntegration: false,
    showAuthNetIntegration: false,
    busifyLink: DEFAULT_LINK,
    busifySlogan: DEFAULT_SLOGAN,
  }
  errors = {
    city: null,
    state: null,
    zip: null,
  }

  enableSmsNumberLabel(smsNumber: string): string {
    return `<p>Enable SMS Number${
      smsNumber ? `: <b>${smsNumber}</b></p>` : '</p>'
    }`
  }

  handleChangeAddressInput(input: string): void {
    this.company.address = { ...this.company.address, street1: input || '' }
  }

  handleChangeAddress(address: Address): void {
    this.errors = {
      city: null,
      state: null,
      zip: null,
    }
    if (address) {
      this.company = { ...this.company, address: { ...address } }
    } else {
      this.company.address.title = null
    }
  }

  clear(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    this.errors = {
      city: null,
      state: null,
      zip: null,
    }
    const isValidAddress = hasRequiredAddressFields(this.company.address)
    if (!(await validateRules(this)) || !isValidAddress) {
      if (!isValidAddress) {
        this.errors = {
          city: !this.company?.address?.city
            ? 'Required to save address'
            : null,
          state: !this.company?.address?.state
            ? 'Required to save address'
            : null,
          zip: !this.company?.address?.postalCode
            ? 'Required to save address'
            : null,
        }
      }
      return
    }
    const unmaskedPhone = this.company.phone.replace(/\D+/g, '')
    this.company.phone = unmaskedPhone

    const newCompany: Partial<Company> = {
      active: true,
      garage: false,
      dotVerified: false,
      name: this.company.name,
      companyType: companyTypes.find((c) => c.companyTypeId === 2),
      emailSenderName: this.company.emailSenderName,
      email: this.company.email,
      phone: this.company.phone,
      opsPhone: this.company.opsPhone,
      dotNumber: this.company.dotNumber,
      showCharterUPIntegration: this.company.showCharterUPIntegration,
      showBusifyPayIntegration: this.company.showBusifyPayIntegration,
      showSquareIntegration: this.company.showSquareIntegration,
      showAuthNetIntegration: this.company.showAuthNetIntegration,
      enableSmsNumber: this.company.enableSmsNumber,
    }

    const addressName = formatAddressName(this.company.address || new Address())
    const centeringCoords = await getCenteringDataByAddress(
      this.company.address
    )
    if (addressName) {
      newCompany.address = {
        ...this.company.address,
        ...centeringCoords,
        name: addressName,
        addressName,
        title: null,
      }
    }

    const createdCompany = (await companyClient.create(newCompany)).data
      ?.company
    this.$emit('company:created', createdCompany.companyId)

    if (
      this.company.enableSmsNumber &&
      this.company.twilioApiPhoneNumber == null
    ) {
      try {
        await smsNumberClient.create(createdCompany.companyId)
      } catch (e: any) {
        EventBus.$emit('snackbar:error', 'Failed to create sms phone number')
      }
    }

    sidebar.close()
  }
}
