import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export default {
  company(payload: FormData): Promise<AxiosResponse> {
    return httpService.post(
      `https://${apiBaseUrl()}/import/customerAccount`,
      payload,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  },
  contact(payload: FormData): Promise<AxiosResponse> {
    return httpService.post(
      `https://${apiBaseUrl()}/import/customer`,
      payload,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  },

  vehicle(payload: FormData): Promise<AxiosResponse> {
    return httpService.post(`https://${apiBaseUrl()}/import/vehicle`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  driver(payload: FormData): Promise<AxiosResponse> {
    return httpService.post(`https://${apiBaseUrl()}/import/driver`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  reservation(payload: FormData): Promise<AxiosResponse> {
    return httpService.post(
      `https://${apiBaseUrl()}/import/reservation`,
      payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
  },
  quote(payload: FormData): Promise<AxiosResponse> {
    return httpService.post(`https://${apiBaseUrl()}/import/quote`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
}
