
import { Vue, Component } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { phoneFormatFilter } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import sidebar from '@/store/modules/sidebar'
import AdminCreateCompanySidebar from '@/components/AdminCreateCompanySidebar.vue'
import HoldUpModal from '@/components/HoldUpModal.vue'
import AdminEditCompanyForm from '@/components/AdminEditCompanyForm.vue'
import client from '@/services/company'
import { Company } from '@/models/dto/Company'

@Component({
  components: { HoldUpModal, CUCollectionTable },
})
export default class AdminCompaniesList extends Vue {
  tableView = client.tableView
  tableItemCount = 0

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '5b35c8a1-0bd1-422a-8f43-4198b7bd3c48',
      text: 'Name',
      value: 'name',
      type: 'link',
      clickEvent: 'companies:open-company-detail',
      filterBySearch: true,
      filterProp: 'name',
    },
    {
      _t_id: 'f74acd44-8149-4d09-a06c-bfc826ad6cdd',
      text: 'Address',
      value: 'address/street1',
    },
    {
      _t_id: '3a23fc4b-9339-4c54-a76a-1c10301556e0',
      text: 'City',
      value: 'address/city',
    },
    {
      _t_id: 'a1549c9c-1505-4e65-8428-d887972e6ada',
      text: 'State',
      value: 'address/state',
    },
    {
      _t_id: '8c3f6f59-96cc-49e5-890f-699ee9e656e6',
      text: 'Primary Email',
      value: 'email',
      filterBySearch: true,
      filterProp: 'email',
    },
    {
      _t_id: '3777276a-c7d6-43c3-9e42-69a88adc8b18',
      text: 'Status',
      value: 'active',
      computedText: (row) => (row.active ? 'Active' : 'Inactive'),
    },
    {
      _t_id: '7b73b408-7572-49fb-853b-81e85551c84d',
      text: 'Company Type',
      value: 'companyType/label',
      filterBySearch: true,
      filterProp: 'companyType/label',
    },
    {
      _t_id: '9fd12749-f966-468a-91e9-cb4b43eaa66b',
      text: 'DOT Number',
      value: 'dotNumber',
      filterBySearch: true,
      filterProp: 'dotNumber',
    },
    {
      _t_id: 'f4bd1799-04e4-4888-943f-bcab83daff5f',
      text: 'Phone Number',
      value: 'phone',
      computedText: (row): string => phoneFormatFilter(row.phone),
    },
  ]

  handleAddCompany(): void {
    sidebar.push({
      component: AdminCreateCompanySidebar,
      on: {
        'admin:company-created': () => {
          sidebar.pop()
          EventBus.$emit('refresh-tableview')
          EventBus.$emit('snackbar:success', 'Company created successfully!')
        },
      },
    })
  }

  openCompanyDetail(row: Company): void {
    sidebar.push({
      component: AdminEditCompanyForm,
      props: {
        companyId: row.companyId,
      },
      on: {
        'admin:company-updated': this.updateCompanyTable,
      },
    })
  }

  updateCompanyTable(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
    EventBus.$emit('snackbar:success', 'Company updated successfully!')
  }

  mounted(): void {
    EventBus.$on('companies:open-company-detail', this.openCompanyDetail)
  }

  beforeDestroy(): void {
    EventBus.$off('companies:open-company-detail', this.openCompanyDetail)
  }
}
